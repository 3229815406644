<script lang="ts" setup> 



    const props = defineProps({ 
        blok: Object 
    })

    const width = (value:string) => {
        return value.split('/')[5].split('x')[0]
    }

    const height = (value:string) => {
        return value.split('/')[5].split('x')[1]
    }

    const richtext = (value:any) => renderRichText(value)

    const { fetchArticles, articles } = useCaseStudies()
    await fetchArticles(props?.blok?.categories, 6, 1)

    const carousel = ref(null)

    const settings = reactive({
        settings: {
            itemsToShow: 1.25,
            snapAlign: 'center',
            wrapAround: true
        }
    })

    const data = reactive({
        breakpoints: {
            320: {
                itemsToShow: 1.25,
                snapAlign: 'center',
                wrapAround: true
            },
            640: {
                itemsToShow: 1.25,
                snapAlign: 'start',
                wrapAround: true
            },
            768: {
                itemsToShow: 1.25,
                snapAlign: 'start',
                wrapAround: false
            },
            1000: {
                itemsToShow: 2,
                snapAlign: 'start',
                wrapAround: false
            },
            1024: {
                itemsToShow: 2,
                snapAlign: 'start',
                wrapAround: false
            },
            1280: {
                itemsToShow: 2,
                snapAlign: 'start',
                wrapAround: false
            },
            1300: {
                itemsToShow: 2,
                snapAlign: 'start',
                wrapAround: false
            },
            1400: {
                itemsToShow: 2,
                snapAlign: 'start',
                wrapAround: false
            },
            1536: {
                itemsToShow: 2,
                snapAlign: 'start',
                wrapAround: false
            },
            1620: {
                itemsToShow: 2,
                snapAlign: 'start',
                wrapAround: false
            },
            1800: {
                itemsToShow: 2,
                snapAlign: 'start',
                wrapAround: false
            }
        }
    })

</script>

<template>
   <div v-editable="blok" class="case-studies-carousel | grid grid-col-1" :class="[blok?.theme || 'dark']">
        <div class="flex flex-col gap-6 lg:gap-8">
      
            <div v-if="blok?.heading || blok?.subheading || blok?.richtext && richtext(blok?.richtext)" class="flex flex-col gap-4 justify-center items-center text-center max-w-[900px] mx-auto">
                <div v-if="blok?.heading || blok?.subheading" class="flex flex-col gap-2 justify-center items-center text-center">
                    <component
                        v-if="blok && blok?.heading" 
                        :is="`headings-${blok?.heading_tag || 'h3'}`" 
                        :value="blok?.heading"
                        :classes="blok?.heading_style || 'h2'" 
                    />
                    <component
                        v-if="blok && blok?.subheading" 
                        :is="`headings-${blok?.subheading_tag || 'h4'}`" 
                        :value="blok?.subheading"
                        :classes="blok?.subheading_style || 'h3'"
                    />
                </div>
                <div v-if="blok?.richtext && richtext(blok?.richtext)" v-html="richtext(blok?.richtext)" class="flex flex-col gap-2 | font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-white-50/75 dark:text-nevada-500"/>
            </div>

            <div v-if="articles && articles.length > 0">
                <Carousel ref="carousel" v-bind="settings" :breakpoints="data.breakpoints">
                    <Slide v-for="article in articles" :key="article.uuid" v-editable="article" class="w-full lg:w-auto lg:max-w-[50%]" aria-hidden="false">
                        <div class="card | w-full h-full m-0 px-1" aria-hidden="false">
                            <div class="card--shadow | relative flex flex-col w-full h-full justify-start items-end text-left">
                                <div class="aspect-[16/9] w-full">
                                    <NuxtImg
                                        v-if="article?.content?.card_image && article?.content?.card_image?.filename"
                                        provider="storyblok"
                                        :width="width(article?.content?.card_image?.filename)"
                                        :height="height(article?.content?.card_image?.filename)" 
                                        :sizes="`xs:100vw sm:${width(article?.content?.card_image?.filename)} md:${width(article?.content?.card_image?.filename)} narrow:${width(article?.content?.card_image?.filename)} lg:${width(article?.content?.card_image?.filename)} xl:${width(article?.content?.card_image?.filename)} 1xl:${width(article?.content?.card_image?.filename)} normal:${width(article?.content?.card_image?.filename)} 2xl:${width(article?.content?.card_image?.filename)} 3xl:${width(article?.content?.card_image?.filename)} wide:${width(article?.content?.card_image?.filename)}`"
                                        quality="75"
                                        fit="in"
                                        placeholder
                                        :src="article?.content?.card_image?.filename"
                                        :alt="article?.content?.card_image?.alt || article?.content?.card_image?.title || article?.content?.card_heading || article?.name || '#'" 
                                        :fetchpriority="'auto'"
                                        :decoding="'async'"
                                        :loading="'lazy'"
                                        class="aspect-[16/9] w-full h-full object-center object-cover rounded overflow-hidden"
                                    />
                                </div>
                                <div class="relative flex flex-col justify-start items-center text-center gap-2 w-full h-full m-0 p-4 pb-0">
                                    <h4 v-if="article?.content?.card_heading || article.name" class="font-hass-display text-h7_mobile md:text-h7_tablet lg:text-h7_desktop font-medium text-elephant-950 line-clamp-2">
                                        {{ article.content.card_heading || article.name }}
                                    </h4>
                                    <div class="flex flex-col gap-2">
                                        <p v-if="article.content.card_excerpt" v-html="article.content.card_excerpt" class="font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-nevada-500 line-clamp-2"/>
                                        <p class="font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-lima-600 underline">Read More</p>
                                    </div>
                                    <NuxtLink :to="`/${article.full_slug}`" :title="article.content.card_heading || article.name" class="absolute top-0 left-0 w-full h-full z-10"/>
                                </div>
                            </div>
                        </div>
                    </Slide>
                </Carousel>
            </div>

            <nav v-if="articles && articles.length > 0" class="flex flex-row gap-1 justify-center items-center">
                <button @click="carousel.prev()" class="prev | w-4 h-4" title="Previous" aria-label="Previous"></button>
                <button @click="carousel.next()" class="next | w-4 h-4" title="Next" aria-label="Next"></button>
            </nav>

            <div v-if="blok?.buttons && blok?.buttons.length > 0" class="flex flex-wrap gap-2 justify-center">
                <StoryblokComponent v-for="button in blok.buttons" :key="button._uid" :blok="button" />
            </div>
            
        </div>
   </div>
</template>

<style lang="scss">
    .case-studies-carousel {

        .carousel {

            @apply w-[calc(100%_+_16px)] -translate-x-[8px] cursor-grab;

            &.is-dragging {
                @apply cursor-grabbing
            }

            .carousel__viewport {
                @apply overflow-visible touch-auto;
            }

        }

        .prev {
            @apply bg-contain bg-center rotate-180;
            background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcon%3C/title%3E%3Cdefs%3E%3Cpath d='M15,0 C23.2842712,0 30,6.71572875 30,15 C30,23.2842712 23.2842712,30 15,30 C6.71572875,30 0,23.2842712 0,15 C0,6.71572875 6.71572875,0 15,0 Z M15,2.0348186 C7.83952804,2.0348186 2.0348186,7.83952804 2.0348186,15 C2.0348186,22.160472 7.83952804,27.9651814 15,27.9651814 C22.160472,27.9651814 27.9651814,22.160472 27.9651814,15 C27.9651814,7.83952804 22.160472,2.0348186 15,2.0348186 Z M13.1907614,7.87616967 L13.2866209,7.95692746 L20.6531795,14.9884701 L13.2981455,22.2388572 C12.9058866,22.625535 12.2744337,22.6210107 11.8877559,22.2287518 C11.5308225,21.8666667 11.5072211,21.3007713 11.8142244,20.9117204 L11.8978612,20.8183622 L17.7882288,15.0116854 L11.9093858,9.3997806 C11.5416016,9.04872246 11.5007943,8.48380704 11.795819,8.08559598 L11.8765768,7.98973645 C12.2276349,7.62195224 12.7925503,7.581145 13.1907614,7.87616967 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Components/Blog-Snippets' transform='translate(-1224.000000, -683.000000)'%3E%3Cg id='Cards' transform='translate(0.031661, 314.000000)'%3E%3Cg id='Card' transform='translate(806.888889, 20.000000)'%3E%3Cg id='Icon' transform='translate(417.079450, 349.000000)'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Combined-Shape' fill='%23002B40' xlink:href='%23path-1'%3E%3C/use%3E%3Cg id='Group' mask='url(%23mask-2)' fill='%23002B40'%3E%3Cg id='Colour'%3E%3Crect id='bg' x='0' y='0' width='30' height='30'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }

        .next {
            @apply bg-contain bg-center;
            background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcon%3C/title%3E%3Cdefs%3E%3Cpath d='M15,0 C23.2842712,0 30,6.71572875 30,15 C30,23.2842712 23.2842712,30 15,30 C6.71572875,30 0,23.2842712 0,15 C0,6.71572875 6.71572875,0 15,0 Z M15,2.0348186 C7.83952804,2.0348186 2.0348186,7.83952804 2.0348186,15 C2.0348186,22.160472 7.83952804,27.9651814 15,27.9651814 C22.160472,27.9651814 27.9651814,22.160472 27.9651814,15 C27.9651814,7.83952804 22.160472,2.0348186 15,2.0348186 Z M13.1907614,7.87616967 L13.2866209,7.95692746 L20.6531795,14.9884701 L13.2981455,22.2388572 C12.9058866,22.625535 12.2744337,22.6210107 11.8877559,22.2287518 C11.5308225,21.8666667 11.5072211,21.3007713 11.8142244,20.9117204 L11.8978612,20.8183622 L17.7882288,15.0116854 L11.9093858,9.3997806 C11.5416016,9.04872246 11.5007943,8.48380704 11.795819,8.08559598 L11.8765768,7.98973645 C12.2276349,7.62195224 12.7925503,7.581145 13.1907614,7.87616967 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Components/Blog-Snippets' transform='translate(-1224.000000, -683.000000)'%3E%3Cg id='Cards' transform='translate(0.031661, 314.000000)'%3E%3Cg id='Card' transform='translate(806.888889, 20.000000)'%3E%3Cg id='Icon' transform='translate(417.079450, 349.000000)'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Combined-Shape' fill='%23002B40' xlink:href='%23path-1'%3E%3C/use%3E%3Cg id='Group' mask='url(%23mask-2)' fill='%23002B40'%3E%3Cg id='Colour'%3E%3Crect id='bg' x='0' y='0' width='30' height='30'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }

    }
</style>
